<template>
	<div 
		id="pageTop"
		class="bg-blue-900 text-white flex justify-center">
		
		<nav class="py-8 container flex flex-col lg:flex-row items-center">
			<div class="flex-none flex px-4 lg:px-0 w-full lg:w-auto">
				<router-link 
					:to="{ name: 'frontpage'}"
					class="flex-grow flex items-center justify-start lg:justify-center ml-4 lg:ml-0">
					
					<img 
						class="mb-3 w-32"
						src="/logo.svg" 
					/>
				</router-link>
				
				<i 
					class="flex-none fas block w-12 h-12 flex justify-center items-center text-xl lg:hidden"
					:class="isOpen ? 'fa-times' : 'fa-bars'"
					@click="isOpen = !isOpen"
				/>
			</div>
			
			<div 
				class="flex-grow flex flex-col lg:flex-row items-center justify-center w-full py-12 lg:py-0"
				v-if="isOpen">
				
				<div class="flex-grow flex flex-col lg:flex-row lg:mx-8 lg:space-x-8 text-center">
					<router-link 
						:to="{ name: 'product'}"
						class="link">
						
						{{$t('general.navigation.product')}}
					</router-link>
					
					<!-- <div 
						@mouseleave="showDropDown = null"
						class="relative">
						
						<div 
							@mouseover="showDropDown = 'industries'" 
							class="link"
							style="border: none!important;">
							
							{{$t('general.navigation.industries')}}
						</div>
						
						<div 
							@mouseover="showDropDown = 'industries'" 
							v-show="showDropDown && showDropDown == 'industries'"
							class="absolute flex flex-col bg-white rounded-b-xl rounded-tr-xl overflow-hidden shadow-xl space-y-2 pb-2"
							style="width: 28rem; z-index: 100; ">
							
							<router-link 
								v-for="(industry, index) in industries"
								:key="'industry_'+industry.routeName"
								:to="{ name: industry.routeName}"
								class="px-6 text-left border-gray-200 pt-4"
								:class="index != 0 ? 'border-t' : ''">
								
								<h3 class="text-blue-900 my-1">{{$t( 'industries.'+industry.code+'.name')}}</h3>
								<p class="text-gray-600 leading-normal">{{$t( 'industries.'+industry.code+'.intro')}}</p>
							</router-link>
						</div>
					</div> -->
					
					<router-link 
						:to="{ name: 'documentation'}"
						class="link">
						
						{{$t('general.navigation.support')}}
					</router-link>
					<!-- 
					<div 
						@mouseleave="showDropDown = null"
						class="relative">
						
						<div 
							@mouseover="showDropDown = 'support'" 
							class="link"
							style="border: none!important;">
							
							{{$t('general.navigation.support')}}
						</div>
						
						<div 
							@mouseover="showDropDown = 'support'" 
							v-show="showDropDown && showDropDown == 'support'"
							class="absolute flex flex-col bg-white rounded-b-xl rounded-tr-xl overflow-hidden shadow-xl space-y-2 pb-2"
							style="width: 28rem; z-index: 100; ">
							
							<router-link 
								v-for="(support, index) in ['documentation','api']"
								:key="'support_'+support"
								:to="{ name: support}"
								class="px-6 text-left border-gray-200 pt-4"
								:class="index != 0 ? 'border-t' : ''">
								
								<h3 class="text-blue-900 my-1">{{$t( 'support.'+support+'.name')}}</h3>
								<p class="text-gray-600 leading-normal">{{$t( 'support.'+support+'.intro')}}</p>
							</router-link>
						</div>
					</div>
					 -->
					<!-- <div 
						@mouseleave="showDropDown = null"
						class="relative">
						
						<div 
							@mouseover="showDropDown = 'support'" 
							class="link"
							style="border: none!important;">
							
							{{$t('general.navigation.support')}}
						</div>
						
						<div 
							@mouseover="showDropDown = 'support'" 
							v-show="showDropDown && showDropDown == 'support'"
							class="absolute flex flex-col bg-white rounded-b-xl rounded-tr-xl overflow-hidden shadow-xl space-y-2 pb-2"
							style="width: 28rem; z-index: 100; ">
							
							<router-link 
								v-for="(support, index) in ['documentation','api']"
								:key="'support_'+support"
								:to="{ name: support}"
								class="px-6 text-left border-gray-200 pt-4"
								:class="index != 0 ? 'border-t' : ''">
								
								<h3 class="text-blue-900 my-1">{{$t( 'support.'+support+'.name')}}</h3>
								<p class="text-gray-600 leading-normal">{{$t( 'support.'+support+'.intro')}}</p>
							</router-link>
						</div>
					</div> -->
					
					<router-link 
						:to="{ name: 'partners'}"
						class="link">
						
						{{$t('general.navigation.partners')}}
					</router-link>
					
					<!-- <router-link 
						:to="{ name: 'api'}"
						class="link">
						
						API
					</router-link> -->
					
					<router-link 
						:to="{ name: 'company'}"
						class="link">
						
						{{$t('general.navigation.company')}}
					</router-link>
				</div>
				
				<div class="flex-none flex flex-col items-center lg:flex-row lg:space-x-8 space-y-4 my-4 lg:space-y-0 lg:my-0 text-center">
					
					<div class="flex justify-center">
						<img src="/css/globe.svg" class="mr-1"/>
						
						<select 
							class="bg-blue-900"
							v-model="language">
							
							<option 
								v-for="l in languages"
								:key="l.name"
								:value="l.code"
								:selected="language == l.code">
								
								{{l.name}}
							</option>
						</select>
					</div>
					
					<a href="https://app2.getcloudcast.com" target="_blank" class="text-yellow-500">
						{{$t('general.navigation.logIn')}}
					</a>
					
					<router-link 
						:to="{ name: 'getStarted'}"
						class="inline-block px-8 py-2 rounded-full text-white border border-white">
						
						{{$t('frontpage.getStarted')}}
					</router-link>
				</div>
			</div>
				
		</nav>
	</div>
</template>

<script>
	export default {
		data () {
			return {
				languages: [
					{
						name: 'Norsk',
						code: 'nb',
					},
					{
						name: 'English',
						code: 'en',
					},
				],
				language: 'en',
				isOpen: true,
				showDropDown: null,
			}
		},
		
		watch: {
			language(to,from) {
				this.onChangeLocale( to );
			},
		},
		
		methods: {
			onChangeLocale( language ){
				this.$i18n.locale = language;
				
				if (this.$moment) {
					switch (language) {
						case 'nb':
							this.$moment.locale('nb');
							break;
						case 'en':
							this.$moment.locale('en');
							break;
						default:
							this.$moment.locale('en');
					}
				}
			},
		},
		
		created() {
			this.language = navigator.language.split('-')[0];
			
			if (screen.width < 1024) {
				this.isOpen = false;
			}
		},
	}
</script>

<style lang="scss" scoped>
	.link {
		@apply border-b-2;
		@apply border-transparent;
		@apply py-2;
		transition: border .25s ease;
		color: #fff!important;
		
		&.router-link-active,
		&:hover {
			// @apply text-white;
			@apply border-b-2;
			@apply border-blue-800;
			// border-color: rgba(255,255,255, .25);
			// @apply border-white;
			@apply py-2;
			// @apply font-bold;
			
		}
	}
</style>
